<template>
  <div class="page">
    <BreadCrumb />
    <el-button class="goBack" plain round size="mini" type="primary" @click="goBack"> 返回 </el-button>
    <div class="page-body">
      <QueryModule :query-module="queryModule" @queryRes="queryRes" @reset="reset">
        <el-col :span="8">
          <el-date-picker v-model="queryModule.timeCond" end-placeholder="结束日期" range-separator="至" start-placeholder="开始日期" type="daterange" />
        </el-col>
        <el-col :span="8">
          <el-form-item label="创建人">
            <el-input v-model="queryModule.createBy" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="作业名称">
            <el-input v-model="queryModule.workName" />
          </el-form-item>
        </el-col>
      </QueryModule>
      <el-button class="margin-10-0" size="small" type="primary" @click="addWork"> 新增作业 </el-button>
      <el-table v-loading="loading" :data="tableData" fit stripe>
        <el-table-column :index="(queryModule.currentPage - 1) * 5 + 1" label="序号" type="index" />
        <el-table-column label="作业名称" prop="workName">
          <template #default="scope">
            <el-button size="mini" type="text" @click="toworkTask(scope.row)">
              {{ scope.row.workName }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="总量/成功/失败/运行" prop="Num" width="200" />

        <el-table-column label="创建人" prop="createBy" />
        <el-table-column label="创建时间" prop="createTime" />
        <el-table-column label="修改人" prop="updateBy" />
        <el-table-column label="修改时间" prop="updateTime" />
        <el-table-column label="操作">
          <template #default="scope">
            <el-tooltip class="item" effect="light" content="修改" placement="top-end">
              <el-button type="primary" circle  size="mini"  @click="editRule(scope.$index,scope.row)" >
                <el-icon :size="15">
                  <i-edit />
                </el-icon>
              </el-button>
            </el-tooltip>
            
            <el-tooltip class="item" content="运行" effect="light" placement="top-end">
              <el-button circle size="mini" type="success" @click="runDialog(scope.row)">
                <el-icon>
                  <i-caret-right />
                </el-icon>
              </el-button>
            </el-tooltip>
            <el-tooltip class="item" content="详情" effect="light" placement="top-end">
              <el-button circle size="mini" type="primary" @click="worDetails(scope.row)">
                <el-icon>
                  <i-fold />
                </el-icon>
              </el-button>
            </el-tooltip>
            
            
            
            <el-tooltip class="item" content="删除" effect="light" placement="top-end">
              <el-button circle size="mini" type="danger" @click="delProject(scope.row)">
                <el-icon>
                  <i-delete-filled />
                </el-icon>
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-model:currentPage="queryModule.currentPage"
        :page-size="pageSize"
        :total="queryModule.totalElements"
        background
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
  <el-dialog v-model="dialogModify" title="修改作业">
    <el-form ref="myWork" :model="myWork" :rules="workRules">
      <el-form-item :label-width="labelWidth" label="作业名称" prop="workName">
        <el-input v-model="myWork.workName" autocomplete="off" placeholder="请输入作业名称" />
      </el-form-item>
    </el-form>
    <el-form ref="myWork" :model="myWork" :rules="workRules">
      <el-form-item :label-width="labelWidth" label="作业名称" prop="workId">
        <el-input v-model="myWork.workId" autocomplete="off" placeholder="请输入作业名称" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="modifyWork('myWork')">确 定</el-button>
        <el-button @click="dialogModify = false">取 消</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="dialogRun" title="运行作业">
    <el-form :model="runProjectData">
      <el-form-item label="是否生成新表">
        <el-switch v-model="runProjectData.isGenerateNewTable" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="runProject('runProjectData')">确 定</el-button>
        <el-button @click="dialogRun = false">取 消</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import BreadCrumb from '/src/components/BreadCrumb' //面包屑
import QueryModule from '/src/components/QueryModule' //查询
import { mapGetters } from 'vuex'

export default {
  //注册组件
  components: {
    BreadCrumb,
    QueryModule,
  },
  data() {
    return {
      loading: true,
      // table数据
      tableData: '', //当前页的 数据
      //翻页
      ajaxPage: 0, //获取第几页
      total: 0, //总条目数
      // Dialog 对话框
      dialogModify: false,
      dialogRun: false,
      queryModule: {
        //查询
        timeCond: null,
        createBy: '',
        workName: '',
        projectId: '',
        totalElements: 0,
        currentPage: 1,
      },
      myWork: {
        workId: '',
        workName: '',
        projectId: '',
      },
      runProjectData: {
        workId: [],
        isGenerateNewTable: false,
      },
      // UsersRules 表单验证，需要在 el-form-item 元素中增加 prop 属性
      workRules: {
        //新增用户表单验证
        workName: [
          {
            required: true,
            message: '作业不可为空',
            trigger: 'blur',
          },
        ],
      },
      labelWidth: this.$store.state.labelWidthDefault, //表单域标签的宽度
      pageP1: '',
      pageP2: '',
    }
  },
  computed: {
    ...mapGetters(['pageSize']),
  },
  //挂在完成 此时一般可以做一些ajax操作，mounted只会执行一次。
  mounted: function () {
    console.log('获取当前用户详细信息')
    this.queryModule['projectId'] = Number(this.$route.query.projectId)
    this.pageP1 = Number(this.$route.query.pageP1)
    if (this.$route.query.pageP2) {
      //说明是 返回上一页 进入的
      this.ajaxPage = this.$route.query.pageP2 - 1
      this.queryModule.currentPage = this.$route.query.pageP2 - 0
    }
    this.workList()
  },
  methods: {
    //清空对象
    reset() {
      this.queryModule['timeCond'] = null
      this.queryModule['createBy'] = ''
      this.queryModule['workName'] = ''
      this.queryModule.currentPage = 1
      this.workList()
    },
    //查询
    queryRes() {
      this.workList()
    },
    //作业列表
    workList() {
      console.log('作业列表')
      var _this = this
      this.$axios
        .POST('/api/v1/core/project/work/all', {
          page: _this.queryModule.currentPage - 1,
          size: _this.pageSize,
          timeCond: _this.queryModule.timeCond,
          createBy: _this.queryModule.createBy,
          workName: _this.queryModule.workName,
          projectId: _this.queryModule.projectId,
        })
        .then(res => {
          _this.loading = false
          let data = res.data
          _this.tableData = data.content
          _this.queryModule.totalElements = data.totalElements
          for (let i = 0; i < _this.tableData.length; i++) {
            if (_this.tableData[i].isRun == true) {
              _this.tableData[i].isRun = '运行中'
            } else {
              _this.tableData[i].isRun = '未运行'
            }
            _this.tableData[i]['Num'] = _this.tableData[i].totalNum + '/' + _this.tableData[i].sucNum + '/' + _this.tableData[i].errNum + '/' + _this.tableData[i].runNum
          }
        })
    },

    //新增 作业
    addWork() {
      this.$router.push({
        name: 'projectWork',
        query: { projectId: this.queryModule.projectId },
      })
    },
    //修改 作业
    editRule(index,row) {
     console.log(index)
     console.log(row)
     this.$router.push({
       name: 'projectWork',
       query: { workId:row.workId,projectId: this.queryModule.projectId },
     })
     
    },
    //删除作业
    delProject(row) {
      var _this = this
      this.$confirm('此操作将永久删除该数据，是否继续操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$axios.DELETE('/api/v1/core/project/work/' + row.workId).then(function () {
            _this.$message({
              showClose: true,
              type: 'success',
              message: '删除成功',
            })
            if (_this.queryModule.currentPage > 1) {
              if (_this.tableData.length == 1) {
                //此页面只有一天数据，删除后显示上一页
                _this.queryModule.currentPage--
              }
            }
            _this.workList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除!',
          })
        })
    },

    //运行作业 模态框
    runDialog(row) {
      this.dialogRun = true
      this.runProjectData.workId = []
      this.runProjectData.workId.push(row.workId)
    },

    runProject() {
      var _this = this

      console.log('运行')
      let datas = this.runProjectData
      console.log(datas)
      this.$axios.POST('/api/v1/core/project/execution/work', datas).then(function () {
        _this.$message({
          type: 'success',
          message: '开始运行!',
        })
        _this.dialogRun = false
        _this.workList()
      })
    },
    handleCurrentChange() {
      this.workList()
    },
    //获取所有任务
    toworkTask(row) {
      this.pageP2 = this.queryModule.currentPage
      this.$router.push({
        name: 'workTask',
        query: {
          workId: row.workId,
          projectId: this.queryModule.projectId,
          pageP1: this.pageP1,
          pageP2: this.pageP2,
        },
      })
    },
    //查看详情
    worDetails(row) {
      this.pageP2 = this.queryModule.currentPage
      this.$router.push({
        name: 'workDetails',
        query: {
          workId: row.workId,
          projectId: this.queryModule.projectId,
          pageP1: this.pageP1,
          pageP2: this.pageP2,
        },
      })
    },
    //返回上一级
    goBack() {
      //带参数跳转
      this.$router.push({ name: 'my_project', query: { pageP1: this.pageP1 } })
    },
  },
}
</script>

<style></style>
